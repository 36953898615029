.glx-home-news-floor {
  margin-top: .24rem * 2;

  > .container {
    margin-left: .24rem * 2;
    display: flex;
    height: .48rem * 2;

    > .title-block {
      width: 2.07rem * 2;
      height: 100%;

      background-image: url("./img/title-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;

      > .new {
        display: block;
        width: .24rem * 2;
        height: .24rem * 2;
        margin-left: .24rem * 2;
      }

      > .title {
        display: block;
        margin-left: .08rem * 2;
        width: 1.12rem * 2;
        height: 0.24rem * 2;
      }
    }

    > .content-block {
      flex-grow: 1;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -10;
        background-image: url("./img/content-bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 100%;
        right: 0;
        left: -1rem
      }

      display: flex;
      align-items: center;

      > .hot {
        display: block;
        width: .20rem * 2;
        height: .24rem * 2;
      }

      > .swiper-wrapper {
        margin-left: 0.14rem * 2;
        //height: 100%;
        flex-grow: 1;
        box-sizing: border-box;
        padding: {
          top: .14rem * 2;
        }


        .news-carousel-item {
          font-size: 0.22rem * 2;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 1;

          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //溢出用省略号显示
          white-space:nowrap; //溢出不换行
        }
      }

      > .more {
        width: .85rem * 2;
        height: 100%;

        text-align: center;

        span {
          font-size: .20rem * 2;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, .6);
          line-height: 1;
        }

        position: relative;

        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: -9;
          background-image: url("./img/split-line.png");
          background-size: auto 100%;
          background-repeat: no-repeat;
          height: 100%;
          width: .43rem * 2;
          left: -0.5rem;
        }
      }
    }
  }
}