.glx-home-recommend-floor {
  height: 2.51rem * 2;
  margin: {
    top: .13rem * 2;
  }

  > .container {
    height: 100%;
    margin: {
      left: .18rem * 2;
    }
    padding: {
      left: .30rem * 2;
      top: .25rem * 2;
    }
    box-sizing: border-box;

    background-image: url("./img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    > .title-floor {
      > .title {
        width: 1.27rem * 2;
        //height: .32rem * 2;
      }
    }

    > .swiper-floor {
      margin: {
        top: .28rem * 2;
      }
      display: flex;
      overflow-x: scroll;

      > .goods {
        flex-shrink: 0;
        flex-basis: 1.38rem * 2;
        height: 1.38rem * 2;

        &:not(:first-child) {
          margin: {
            left: .12rem * 2;
          }
        }

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background-color: #fff;
        border-radius: 24px 4px 24px 4px;

        > img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}