.glx-home-seckill-floor {
  margin: {
    top: .48rem;
  }
  height: 3.15rem * 2;

  > .container {
    height: 100%;
    margin: {
      left: .48rem;
    }
    box-sizing: border-box;
    padding: {
      top: 1.6rem;
    }
    position: relative;
    background-image: url("./img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    > .title-block {
      position: absolute;

      //width: 2.87rem * 2;
      //height: .68rem * 2;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: {
        left: .09rem * 2;
        right: .24rem * 2;
        top: .09rem * 2;
        bottom: .20rem * 2;
      }

      background-image: url("./img/title-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;
      box-sizing: border-box;

      > img.title {
        display: block;
        width: 1.32rem * 2;
      }

      > .left-time {
        margin-left: .26rem * 2;

        > .label {
          font-size: .18rem * 2;
          font-family: PingFang SC;
          font-weight: 500;
          font-style: italic;
          color: #FFFFFF;
          line-height: 1;
        }

        > .time {
          margin-top: 0.05rem * 2;
          font-size: .22rem * 2;
          font-family: PingFang SC;
          font-weight: bold;
          font-style: italic;
          color: #FFFFFF;
          line-height: 1;
        }
      }
    }

    > .enter-block {
      position: absolute;
      top: .21rem * 2;
      right: 0;
      //width: 1.50rem * 2;
      //height: .35rem * 2;
      box-sizing: border-box;
      padding: {
        top: .03rem * 2;
        bottom: .03rem * 2;
        left: .03rem * 2;
        right: .13rem * 2;
      }

      background-image: url("./img/enter-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      display: flex;
      align-items: center;

      > .enter-star {
        display: block;
        width: .29rem * 2;
        height: .29rem * 2;
      }

      > .enter-text {
        display: block;
        margin-left: .07rem * 2;
        font-size: .20rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1;
      }

      > .enter-arrow {
        display: block;
        margin-left: .07rem * 2;
        width: .16rem * 2;
        height: .16rem * 2;
      }
    }

    > .goods-list {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

      > .goods {
        width: 1.42rem * 2;
        flex-shrink: 0;

        &:first-child {
          margin-left: .24rem * 2;
        }

        &:not(:first-child) {
          margin-left: .12rem * 2;
        }

        > .img-block {
          width: 1.42rem * 2;
          height: 1.42rem * 2;
          border-radius: .04rem * 2;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          background-color: #fff;

          > .main-img {
            width: 100%;
          }
        }

        > .info {
          box-sizing: border-box;
          padding-top: .20rem * 2;

          > .current-price {
            font-size: .24rem * 2;
            font-family: Arial;
            font-weight: 900;
            color: #FE3A5F;
            line-height: 1;
          }

          > .origin-price {
            margin-top: .07rem * 2;
            font-size: .20rem * 2;
            font-family: Arial;
            font-weight: 400;
            text-decoration: line-through;
            color: #A9A9A9;
            line-height: 1;
          }
        }
      }
    }
  }
}