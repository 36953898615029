.glx-home-swiper-floor1 {
  display: flex;
  margin: {
    top: .4rem;
  }
  width: 100%;
  overflow-x: scroll;
  height: 2.02rem * 2;

  > .swiper-item {
    width: 7.96rem;
    height: 2.02rem * 2;
    flex-shrink: 0;

    @mixin border-radius() {
      border-radius: .24rem;
    }
    @include border-radius;

    margin: {
      left: .48rem;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include border-radius;
    }
  }
}