.glx-home-top-floor {
  box-sizing: border-box;
  padding: {
    top: 1.26rem * 2;
    //bottom: .24rem * 2;
  }
  height: 4.03rem * 2;
  position: relative;

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }

  > .banner {
    margin: {
      left: .48rem;
      right: .48rem;
    }
    height: 2.53rem * 2;
    position: relative;

    > .swiper-container {
      height: 100%;
      border-radius: .2rem .2rem .2rem 2.2rem;
      overflow: hidden;

      .swiper-slide {
        > .img-block {
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

    }


    .swiper-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: .6rem;
      display: flex;
      justify-content: center;

      .pagination-item {
        &:not(:first-child) {
          margin-left: .4rem;
        }

        width: .44rem;
        height: .06rem;
        background: rgba(255, 255, 255, .3);
        border-radius: .04rem;

        &.active {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}