.glx-home-category-floor {
  margin-top: .24rem * 2;
  margin-right: .24rem * 2;

  > .banner-container {
    height: 2.84rem * 2;
    box-sizing: border-box;
    padding: {
      top: .20rem * 2;
      left: .48rem * 2;
    }
    position: relative;

    @mixin bg() {
      $bg-img-list: (
              url("./img/banner-bg-1.png"),
              url("./img/banner-bg-2.png"),
              url("./img/banner-bg-3.png"),
      );
      @for $i from 0 to length($bg-img-list) {
        &.index-#{$i} {
          background-image: nth($bg-img-list, $i + 1);
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }

    @include bg;

    > .enter {
      position: absolute;
      display: flex;
      align-items: center;
      right: 0;
      top: .23rem * 2;

      height: .29rem * 2;
      border-radius: .15rem * 2 0 0 .15rem * 2;

      @mixin bg() {
        $bg-color-list: (
                rgba(155, 193, 228, 1),
                rgba(209, 159, 241, 1),
                rgba(146, 226, 184, 1)
        );

        @for $i from 0 to length($bg-color-list) {
          &.index-#{$i} {
            background-color: nth($bg-color-list, $i + 1);
          }
        }
      }
      @include bg;

      box-sizing: border-box;
      padding: {
        left: .15rem * 2;
        right: .08rem * 2;
      }

      > .enter-text {
        font-size: .20rem * 2;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 1;
      }

      > .enter-arrow {
        display: block;
        margin-left: 0.08rem * 2;
        width: .16rem * 2;
        height: .16rem * 2;
      }
    }

    > .title-floor {
      display: flex;
      align-items: center;

      > .title {
        font-size: .32rem * 2;
        font-family: PingFang SC;
        font-weight: 800;
        color: #1E1E1E;
        line-height: 1;
      }

      > .hot-word-block {
        margin-left: .13rem * 2;
        display: flex;

        height: .30rem * 2;
        border-radius: .15rem * 2;

        box-sizing: border-box;
        padding: {
          right: .20rem * 2;
        }


        @mixin bg() {
          $bg-list: (
                  linear-gradient(-90deg, #6071E9, #5ABBEC),
                  linear-gradient(-90deg, #A13DD5, #E561DB),
                  linear-gradient(-90deg, #1ACEC0, #81E6E7)
          );

          @for $i from 0 to length($bg-list) {
            &.index-#{$i} {
              background: nth($bg-list, $i + 1);
            }
          }

        }

        @include bg;

        max-width: 1.90rem * 2;

        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行


        > .icon {
          display: block;

          width: 0.30rem * 2;
          height: 0.30rem * 2;
          border-radius: 50%;
        }

        > .hot-word-list {
          margin-left: .11rem * 2;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding-top: 0.02rem;

          > .hot-word {
            display: block;
            font-size: .20rem * 2;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 1;

            &:not(:first-child) {
              margin-left: .09rem * 2;
            }
          }
        }
      }
    }

    > .banner-floor {
      margin-top: .24rem * 2;

      width: 6.54rem * 2;
      height: 1.80rem * 2;
      position: relative;

      > .swiper-container {
        border-radius: .12rem * 2;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .swiper-slide {
          > .img-block {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

    }
  }

  > .goods-list-block {
    height: 1.80rem * 2;
    position: relative;
    margin: {
      top: .24rem * 2;
    }
    box-sizing: border-box;
    padding: {
      right: 0.08rem;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -100;
      background-image: url("img/goods-list-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      left: 0;
      right: -0.03rem;
      bottom: 0;
      top: -2.5rem;
    }

    > .goods-list {
      height: 1.24rem * 2;
      padding: {
        right: .24rem * 2;
      }

      > .goods-list-container {
        display: flex;
        box-sizing: border-box;
        overflow-x: auto;

        > .goods {
          flex-basis: 1.24rem *2;
          width: 0;
          height: 1.24rem * 2;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          flex-shrink: 0;
          background-color: #fff;
          border-radius: 0.06rem * 2;

          &:not(:first-child) {
            margin-left: .12rem * 2;
          }

          > .main-img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}