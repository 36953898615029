.glx-home-adv-floor1 {
  margin-top: .10rem * 2;

  > .banner {
    width: 7.07rem * 2;
    height: 1.58rem * 2;
    margin: {
      left: .34rem;
      right: .52rem;
    }

    > .swiper-container {
      height: 100%;

      > .swiper-wrapper {
        > .swiper-slide {
          .img-block {
            width: 7.07rem * 2;
            height: 1.58rem * 2;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}