.glx-home-nav-floor {
  margin: {
    top: 1rem;
  }
  box-sizing: border-box;
  padding: {
    left: .24rem * 2;
    right: .24rem * 2;
  };
  display: flex;
  position: relative;
  justify-content: space-between;

  > .nav-item {
    flex-shrink: 1;
    box-sizing: border-box;
    padding: {
      bottom: .2rem;
    }

    .icon {
      display: block;
      width: 100%;
    }

    .text {
      display: block;
      text-align: center;

      font-size: .48rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1;
    }
  }

  > .bg {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -100;
    width: 100%;
    height: 5.4rem;
  }
}