.glx-home-header {
  position: absolute;
  z-index: 10;
  top: .67rem * 2;
  display: flex;
  align-items: center;
  padding: {
    left: .24rem * 2;
    right: .26rem * 2;
  }
  box-sizing: border-box;
  width: 100%;

  > .location-icon {
    display: block;
    width: .26rem * 2;
    height: .30rem * 2;
  }

  > .location-text {
    font-size: .24rem * 2;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1;
    margin: {
      left: .13rem * 2;
    }
  }

  > .search-block {
    box-sizing: border-box;
    padding: {
      left: .10rem;
      top: .10rem;
      bottom: .10rem;
    };
    margin: {
      left: auto;
    }

    > .search-icon {
      width: .30rem * 2;
      height: .30rem * 2;
      display: block;
    }
  }

  > .user-block {
    box-sizing: border-box;
    margin: {
      left: .18rem * 2;
    }
    padding: {
      left: .10rem;
      top: .10rem;
      bottom: .10rem;
    };

    > .user-icon {
      width: .26rem * 2;
      height: .32rem * 2;
      display: block;
    }
  }
}