.glx-order {
  order-pay-select {
    $padding-horizontal: 0.64rem;
    $padding-vertical: 0.82rem;
    width: calc(14.2rem - #{$padding-horizontal} * 2);
    height: calc(4.24rem - #{$padding-vertical} * 2);
    background: rgba(255, 255, 255, 1);
    border: 0px solid rgba(242, 242, 242, 1);
    border-radius: 0.3rem;
    margin: auto;
    margin-top: 0.4rem;
    padding: $padding-vertical $padding-horizontal;

    .option {
      display: flex;
      align-items: center;

      .pay-icon {
        display: block;
        width: 0.64rem;
        height: 0.64rem;
      }

      .pay-name {
        display: block;
        margin: {
          left: .36rem;
        }
        font-size: .48rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(31, 31, 31, 1);
      }

      .radio-btn {
        margin: {
          left: auto;
        }
        width: .64rem;
        height: .64rem;
        border: .04rem solid rgba(220, 220, 220, 1);
        border-radius: 50%;

        &.active {
          background: rgba(250, 22, 39, 1);
        }
      }
    }
  }
}

.order-body {
  background-color: #f9f9f9;
  border-radius: 0.3rem;
}
